import $ from 'jquery'

$(() =>
  $('.site-header__announcement').each(function () {
    return new HeaderAnnouncementManager(this)
  })
)

class HeaderAnnouncementManager {
  constructor(elm) {
    this.hide = this.hide.bind(this)
    this.updateHeightCSSVariable = this.updateHeightCSSVariable.bind(this)
    this.elm = $(elm)
    this.url = new URL(location.href)
    this.isCustomizingTheme = this.url.searchParams.get('theme_customization')
    this.localStorageKey = `simplero-header-announcement-${this.elm.data(
      'content-digest'
    )}`

    this.showIfNotAlreadyDismissed()
  }

  showIfNotAlreadyDismissed() {
    if (
      localStorage.getItem(this.localStorageKey) !== 'dismissed' ||
      this.isCustomizingTheme ||
      !this.elm.data('allow-dismiss')
    ) {
      this.elm.slideDown(150, this.updateHeightCSSVariable)
      this.elm.find('.site-header__announcement__dismiss').click((e) => {
        e.preventDefault()
        e.stopImmediatePropagation()
        this.hide()
      })
    }
  }

  hide() {
    localStorage.setItem(this.localStorageKey, 'dismissed')
    this.updateHeightCSSVariable(0)
    this.elm.slideUp()
  }

  updateHeightCSSVariable(heightInPixel) {
    if (heightInPixel === undefined) {
      heightInPixel = this.elm.outerHeight()
    }
    heightInPixel = `${heightInPixel}px`
    $('header')[0].style.setProperty(
      '--header-announcement-height',
      heightInPixel
    )
  }
}
